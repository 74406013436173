import { types, Device } from "mediasoup-client"
import { NewCreateRoomResT, connectTransportResT, consumeResT, createWebRtcTransportResT, produceDataResT, produceResT, sendConnectWebRtcTransportReq, sendCreateWebrtcTransportReq, sendExitRoomReq, sendNewCreateRoom, sendProduceDataReq, sendProduceReq } from "../services/mediaRequest"
import { httpMediaUrl } from "./Constants"
import { ConsumedDataPeersT, ConsumedPeersT, DoDataConsumePayloadT, PeerLeftTheCallEventPayloadT, TRANSPORT_TYPE, LogLevelT, LogScopeT, Process_Status } from "./models"



class CallManager {
  private rtpCapabilities: types.RtpCapabilities | undefined
  private token: string | undefined
  private callId: string | undefined
  private name: string | undefined
  private publickey: string | undefined
  private mediaId: string | undefined
  private device: types.Device | undefined
  private callCreatedAt: number | undefined
  private peerCreatedAt: number | undefined
  private sendTransport: types.Transport | undefined
  private recieveTransport: types.Transport | undefined
  private producer: types.Producer | undefined
  private dataProducer: types.DataProducer | undefined
  private websocket_media: WebSocket | undefined
  private websocket_core: WebSocket | undefined
  private mediaUrl = httpMediaUrl
  private consumers: { [otherPeerId: string]: types.Consumer } = {}
  private consumeParamQueue: { [otherPeerId: string]: consumeResT } = {}
  private consumedPeers: ConsumedPeersT = {}
  private dataConsumers: { [otherPeerId: string]: types.DataConsumer } = {}
  private consumeDataParamQueue: { [otherPeerId: string]: DoDataConsumePayloadT } = {}
  private consumedDataPeers: ConsumedDataPeersT = {}
  private pingInterval: NodeJS.Timeout | undefined
  processStatus: Process_Status = Process_Status.CREATINGCALL

  statusCallback: (status: string) => void = () => { };
  makeAwareDispatcher: (processStatus: Process_Status) => void | undefined

  parentHtmlRef: React.MutableRefObject<HTMLDivElement> | undefined = undefined
  audioElements: { [consumerId: string]: HTMLAudioElement } = {}

  hasInprocessCallInitialization: boolean = false
  logger(log: string, data: { fileName?: string, functionName: string[], logPrefix?: string, logSuffix?: string, logSeqNum: string | number, }, isError: boolean, scopes: LogScopeT[], level: LogLevelT) {     //???
    let { functionName, logSeqNum, fileName, logPrefix, logSuffix } = data

    fileName = fileName ? fileName : "callManager"


    const info = `${ logPrefix ? logPrefix : '' } -- ${ fileName }.${ functionName.join('.') }#${ logSeqNum }::: ${ log } ${ logSuffix ? '-- ' + logSuffix : '' }`
    if (isError) {
      return console.error(info)
    }
    console.log(info)

  }
  // constructor() { }
  setToken(token: string) {
    this.token = token
  }
  setCallId(callId: string) {
    this.callId = callId
  }
  setName(name: string) {
    this.name = name
  }
  setPublicKey(publicKey: string) {
    this.publickey = publicKey
  }
  setParentHtmlRef(parentHtmlRef: React.MutableRefObject<HTMLDivElement>) {
    this.parentHtmlRef = parentHtmlRef
  }

  setMediaId(mediaId: string) {
    this.mediaId = mediaId
  }
  setMediaUrl(mediaIp: string) {
    this.mediaUrl = mediaIp
  }
  setInprocessCallInitialization(doWeHaveInprocessCallInitialization: boolean) {
    this.hasInprocessCallInitialization = doWeHaveInprocessCallInitialization
  }
  setMakeAwareDispatcher = (makeAware: (processStatus: Process_Status) => void) => {
    this.makeAwareDispatcher = makeAware
  }
  setProcessStatus(status: Process_Status) {
    this.processStatus = status
    this.makeAwareDispatcher(status)
  }
  muteMicrophone() {
    this.producer.pause()
  }
  unMuteMicrophone() {
    this.producer.resume()
  }
  CreateMediaWsConnecton(callId: string, token: string, accountId: string, mediaIp: string) {
    const socketNewUrl = `wss://${ mediaIp }?token=${ token }&talkHash=${ callId }&publicKey=publicKey&callId=${ callId }&peerId=${ accountId }&peerName=webCall`
    this.websocket_media = new WebSocket(socketNewUrl)
    this.addListenerToWebSocket()
  }
  addListenerToWebSocket() {
    if (!this.websocket_media) {
      throw new Error('there is no websocket ')
    }
    this.websocket_media.onopen = () => {
      this.logger(``, { functionName: ['mediaWs', "@open"], logSeqNum: "Start" }, false, [], LogLevelT.info)

    }
    this.websocket_media.onclose = (ev) => {

      this.logger(`,  reason: ${ ev.reason }`, { functionName: ['mediaWs', "@close"], logSeqNum: "Start" }, false, [], LogLevelT.info)

    }
    this.websocket_media.onerror = (err) => {
      this.logger(``, { functionName: ['mediaWs', "@error"], logSeqNum: "Start" }, true, [], LogLevelT.error)

    }
    this.websocket_media.onmessage = async (message) => {

      const eve = JSON.parse(message.data)
      this.logger(`mediaUrl: ${ this.websocket_media.url }---event: ${ JSON.parse(message.data).event }---payload: ${ JSON.stringify(eve.data) }`, { functionName: ['mediaWsEvent'], logSeqNum: "Start" }, false, [], LogLevelT.info)


      // console.log(`###WS_MEDIA @message ---> `, JSON.parse(message.data).event)

      // console.log('wsEvent#1::: event: ', eve.event, ', payload: ', eve.data);
      switch (eve.event) {
        case 'doConsume': {
          console.log(`WBSocket message##::doConsume`);
          const payload = eve.data as consumeResT
          this.consumeWithoutSendingReq(payload)
          break;
        }

        case 'PeerLeftTheCall': {
          const payload = eve.data as PeerLeftTheCallEventPayloadT

          break;
        }
        case 'doDataConsume': {
          const payload = eve.data as DoDataConsumePayloadT
          if (!this.recieveTransport) {
            this.queueTheConsumeDataParam(payload)
          } else {
            this.consumeDataWithoutSendingReq(payload)
          }
          break;
        }
        case 'exitRoom': {
          this.setProcessStatus(Process_Status.EXITROOM)
        }
      }
    }
  }
  CreateCoreWsConnecton(token: string) {
    // console.log('web socket', this.websocket_core);

    if (this.websocket_core && this.websocket_core.readyState !== WebSocket.CLOSED) {
      return
    }
    // const socketUrl = `ws://192.168.10.110/v1/subscribe/${ token }`
    // const socketUrl = `wss://coreapi.palphone.com/v1/subscribe/${ token }`
    const socketUrl = `wss://stg-coreapi.palphone.com/v1/subscribe/${ token }`
    // const socketUrl = `wss://coreapi.stage.k8s.palphone.net/v1/subscribe/${ token }`
    this.websocket_core = new WebSocket(socketUrl)
    // console.log('web core socker connection');
    this.addListenerToCoreWebSocket((callResponseType) => {
      console.log("Received callResponseType in React component:", callResponseType);
    })
    this.statusCallback(this.getWebSocketStatus());
    // Start the interval to check the connection status every 3 seconds

  }
  setStatusCallback(callback: (status: string) => void) {
    this.statusCallback = callback;
  }
  getWebSocketStatus(): string {
    if (!this.websocket_core) {
      return 'WebSocket is not initialized';
    }
    switch (this.websocket_core.readyState) {
      case WebSocket.CONNECTING:
        return 'WebSocket is connecting';
      case WebSocket.OPEN:
        return 'open';
      case WebSocket.CLOSING:
        return 'closing';
      case WebSocket.CLOSED:
        return 'closed';
      default:
        return 'Unknown WebSocket state';
    }
  }
  addListenerToCoreWebSocket(callback) {
    if (!this.websocket_core) {
      throw new Error('there is no websocket ')
    }
    this.websocket_core.onopen = () => {
      this.logger(``, { functionName: ['coreWS', "@open"], logSeqNum: "Start" }, false, [], LogLevelT.info)
      this.statusCallback('open');
      this.startPingPong();
    }
    this.websocket_core.onclose = (ev) => {
      console.log('closeeeeeeeeee');

      // this.CreateCoreWsConnecton(this.token);
      this.logger(`,  reason: ${ ev.reason }`, { functionName: ['coreWs', "@close"], logSeqNum: "Start" }, false, [], LogLevelT.info)
      this.statusCallback('close');
      clearInterval(this.pingInterval);
    }
    this.websocket_core.onerror = (err) => {
      this.logger(``, { functionName: ['coreWs', "@error"], logSeqNum: "Start" }, true, [], LogLevelT.error)
      this.statusCallback('WebSocket error');
    }
    this.websocket_core.onmessage = (message) => {
      // Check if WebSocket is not open and retry connection
      if (this.websocket_core.readyState !== WebSocket.OPEN) {
        this.CreateCoreWsConnecton(this.token);
        return;
      }
       // Handle pong message
    if (message.data.includes('pong')) {
      console.log('Pong received');
      return;
    }
      // console.log('original message in web socket ', message);
      if (message.data.includes('callResponseType')) {
        // console.log('original message in web socket in push ', message.data);
        const eve = JSON.parse(message.data)
        // console.log('call manager call response', eve.callResponseType);
        if (eve.callResponseType) {
          callback(eve);
        // this.logger(`mediaUrl:---event: ${ JSON.parse(message.data).event }---payload: ${ JSON.stringify(eve.data) }`, { functionName: ['mediaWsEvent'], logSeqNum: "Start" }, false, [], LogLevelT.info)
        }
        
      } else {
        // console.log('original message in web socket in proto', message);
        callback(message.data);
      }
    }

  }  
  sendMessage(data) {
    if (this.websocket_core && this.websocket_core.readyState === WebSocket.OPEN) {
      console.log('send data', data);
      this.websocket_core.send(data);
    } else {
      console.error('WebSocket is not open');  
    }
  }

  pingWebSocket() {
    if (this.websocket_core && this.websocket_core.readyState === WebSocket.OPEN) {
      const pingMessage = JSON.stringify({ type: 'ping' }); // Customize the ping message as needed
      this.websocket_core.send(pingMessage);
      console.log('Ping sent');
    }
  }
  startPingPong() {
    this.pingInterval = setInterval(() => {
      this.pingWebSocket();
    }, 50000); // 30 seconds interval
  }
  private async sendCreateRoomReq() {
    console.log('createRoomReq#start');

    if (!this.token) {
      throw new Error('there is no token')
    }
    if (!this.callId) {
      throw new Error('there is no callid')
    }
    if (!this.name) {
      throw new Error('there is no name')
    }
    if (!this.publickey) {
      throw new Error('there is no publickey')
    }
    try {

      const res = await sendNewCreateRoom(
        this.token,
        {
          callId: this.callId,
          name: this.name,
          publicKey: this.publickey,
          isLetsCall: true

        },
        this.mediaUrl
      )

      if (!res.data) {
        throw new Error('there is no data')
      }

      const data = res.data as NewCreateRoomResT
      if (data.error) {
        throw new Error(data.message)
      }
      if (!data.data) {
        throw new Error('there is no server data')
      }



      // console.log(`createRoomReq#1::: data: ${ JSON.stringify(data.data) }`);
      this.callCreatedAt = data.data.callCreatedAt
      this.peerCreatedAt = data.data.peerCreatedAt
      console.log(`createRoomReq#end::: this.callCreatedAt:${ this.callCreatedAt },  this.peerCreatedAt:${ this.peerCreatedAt }`);
      return data

    } catch (error) {
      console.log('createRoomReq#catch::: err: ', error);
      this.setProcessStatus(Process_Status.APIERROR)
      throw error
    }
  }
  // private async sendJoinRoomReq() {
  //   console.log('sendJoinRoomReq#start:::');
  //   if (!this.token) {
  //     throw new Error('there is no token')
  //   }
  //   if (!this.callId) {
  //     throw new Error('there is no callid')
  //   }
  //   if (!this.name) {
  //     throw new Error('there is no name')
  //   }
  //   if (!this.publickey) {
  //     throw new Error('there is no publickey')
  //   }
  //   if (!this.mediaId) {
  //     throw new Error('there is no publickey')
  //   }
  //   if (!this.callCreatedAt) {
  //     throw new Error('there is no publickey')
  //   }
  //   try {
  //     const res = await sendJoinReq(this.token, { mediaId: this.mediaId, callCreatedAt: this.callCreatedAt, callId: this.callId, name: this.name, publicKey: this.publickey }, this.mediaUrl)
  //     if (!res.data) {
  //       throw new Error('there is no data')
  //     }
  //     const data = res.data as joinRestT
  //     if (data.error) {
  //       throw new Error(data.message)
  //     }
  //     if (!data.data) {
  //       throw new Error('there is no server data')
  //     }
  //     const device = new Device()
  //     this.device = device
  //     this.callCreatedAt = data.data.callCreatedAt
  //     this.peerCreatedAt = data.data.peerCreatedAt
  //     await device.load({ routerRtpCapabilities: data.data.rtpcapabilities })
  //     console.log('sendJoinRoomReq#end:::');

  //   } catch (error) {
  //     console.log('sendJoinRoomReq#catch::: err: ', error);
  //     throw error
  //   }
  // }
  async sendCreateTransportsReq({ token, callId, name, approvedMediaId, rtpCapabilities }: { token: string, callId: string, name: string, approvedMediaId: string, rtpCapabilities?: types.RtpCapabilities }) {


    try {
      let createSendTransportsRes
      createSendTransportsRes = await sendCreateWebrtcTransportReq(
        token,
        {
          callId,
          rtpCapabilities,
          name,
          mediaId: approvedMediaId,
          callCreatedAt: this.callCreatedAt,
          peerCreatedAt: this.peerCreatedAt
        },
        this.mediaUrl
      )

      if (![200, 201].includes(createSendTransportsRes.status)) {
        throw new Error('something went wrong in sending createTransport request')
      }


      const createSendTransportData = createSendTransportsRes.data as createWebRtcTransportResT
      return createSendTransportData
    }
    catch (err) {
      this.setProcessStatus(Process_Status.APIERROR)
      throw err

    }




  }
  async sendConnectTransportReq(type: TRANSPORT_TYPE, dtlParameters: types.DtlsParameters) {
    console.log('sendConnectTransportReq#start:::');

    if (!this.token) {
      throw new Error('there is no token')
    }
    if (!this.mediaId) {
      throw new Error('there is no media Id')
    }
    if (!this.callCreatedAt) {
      throw new Error('there is no callCreatedAt')
    }
    if (!this.peerCreatedAt) {
      throw new Error('there is no peerCreatedAt')
    }
    if (!this.name) {
      throw new Error('there is no name')
    }
    if (!this.callId) {
      throw new Error('there is no call id')
    }

    if (!this.sendTransport) {
      throw new Error('there is no rptCapabilities')
    }
    try {
      const res = await sendConnectWebRtcTransportReq(
        this.token,
        {
          mediaId: this.mediaId,
          callCreatedAt: this.callCreatedAt,
          peerCreatedAt: this.peerCreatedAt,
          callId: this.callId,
          name: this.name,
          transportId: this.sendTransport?.id,
          dtlsParameters: dtlParameters,
          type: type
        }, this.mediaUrl)
      if (!res.data) {
        throw new Error('there is no response')
      }
      const data = res.data as connectTransportResT
      if (data.error) {
        throw new Error(data.message)
      }
      console.log('sendConnectTransportReq#end:::');
      return data
    } catch (error) {
      console.log('sendConnectTransportReq#catch::: err: ', error);
      this.setProcessStatus(Process_Status.APIERROR)
      throw error
    }

  }
  async sendingProduceReq(kind: types.MediaKind, rtpParameters: types.RtpParameters) {
    if (!this.token) {
      throw new Error('there is no token')
    }
    if (!this.mediaId) {
      throw new Error('there is no media Id')
    }
    if (!this.callCreatedAt) {
      throw new Error('there is no callCreatedAt')
    }
    if (!this.peerCreatedAt) {
      throw new Error('there is no peerCreatedAt')
    }
    if (!this.name) {
      throw new Error('there is no name')
    }
    if (!this.callId) {
      throw new Error('there is no call id')
    }

    if (!this.sendTransport) {
      throw new Error('there is no rptCapabilities')
    }
    try {
      const res = await sendProduceReq(this.token, { mediaId: this.mediaId, callCreatedAt: this.callCreatedAt, peerCreatedAt: this.peerCreatedAt, callId: this.callId, name: this.name, kind, producerTransportId: this.sendTransport.id, rtpParameters }, this.mediaUrl)
      if (!res.data) {
        throw new Error('there is no data')
      }
      const data = res.data as produceResT
      if (data.error) {
        throw new Error(data.message)
      }
      if (!data.data) {
        throw new Error('there is no server data')
      }
      return data as Required<produceResT>
    } catch (error) {
      this.setProcessStatus(Process_Status.APIERROR)
      throw error
    }
  }
  async sendProduceDataReq(sctpStreamParameters: types.SctpStreamParameters, label: string, protocol: "sctp" | "direct", appData: any) {
    if (!this.token) {
      throw new Error('there is no token')
    }
    if (!this.mediaId) {
      throw new Error('there is no media Id')
    }
    if (!this.callCreatedAt) {
      throw new Error('there is no callCreatedAt')
    }
    if (!this.peerCreatedAt) {
      throw new Error('there is no peerCreatedAt')
    }
    if (!this.name) {
      throw new Error('there is no name')
    }
    if (!this.callId) {
      throw new Error('there is no call id')
    }

    if (!this.sendTransport) {
      throw new Error('there is no rptCapabilities')
    }
    try {
      const res = await sendProduceDataReq(this.token, {
        mediaId: this.mediaId, callCreatedAt: this.callCreatedAt, peerCreatedAt: this.peerCreatedAt, callId: this.callId, name: this.name, producerTransportId: this.sendTransport.id,
        sctpStreamParameters, label, protocol, appData
      }, this.mediaUrl)
      if (!res.data) {
        throw new Error('there is no data')
      }
      const data = res.data as produceDataResT
      if (data.error) {
        throw new Error(data.message)
      }
      return data
    } catch (error) {
      this.setProcessStatus(Process_Status.APIERROR)
      throw error
    }

  }
  async sendExitRoomReq({ token, callId, name, approvedMediaId, isForce }: { token: string, callId: string, name: string, approvedMediaId: string, isForce: boolean }) {

    try {
      const exitRoomRes = await sendExitRoomReq(
        token,
        { callId, isForce, name, mediaId: approvedMediaId, callCreatedAt: this.callCreatedAt, peerCreatedAt: this.peerCreatedAt }, this.mediaUrl
      )
    }
    catch (err) {
      // this.setProcessStatus(Process_Status.APIERROR)
      // throw err
    }
  }
  async createTransports() {

    try {
      this.logger(``, { functionName: ['createTransports'], logSeqNum: "start" }, false, [], LogLevelT.info)
      if (!this.token) {
        throw new Error('there is no token')
      }
      if (!this.mediaId) {
        throw new Error('there is no media Id')
      }
      if (!this.callCreatedAt) {
        throw new Error('there is no callCreatedAt')
      }
      if (!this.peerCreatedAt) {
        throw new Error('there is no peerCreatedAt')
      }
      if (!this.name) {
        throw new Error('there is no name')
      }
      if (!this.callId) {
        throw new Error('there is no call id')
      }

      if (!this.device) {
        throw new Error('there is no device')
      }
      try {
        const createTransportsRes = await this.sendCreateTransportsReq({ callId: this.callId, token: this.token, approvedMediaId: this.mediaId, rtpCapabilities: this.device.rtpCapabilities, name: this.name })
        //if there is no parameters of the 'SEND | 'RECIEVE', we have to throw an error
        if (createTransportsRes.data.transports[TRANSPORT_TYPE.send] && createTransportsRes.data.transports[TRANSPORT_TYPE.recieve]) {
          this.logger(``, { functionName: ['createTransports'], logSeqNum: 1 }, false, [], LogLevelT.info)

          //then we have to create a send transport
          this.sendTransport = this.device.createSendTransport({
            dtlsParameters: createTransportsRes.data.transports[TRANSPORT_TYPE.send].dtlsParameters,
            id: createTransportsRes.data.transports[TRANSPORT_TYPE.send].id,
            iceCandidates: createTransportsRes.data.transports[TRANSPORT_TYPE.send].iceCandidates,
            iceParameters: createTransportsRes.data.transports[TRANSPORT_TYPE.send].iceParameters,
            sctpParameters: createTransportsRes.data.transports[TRANSPORT_TYPE.send].sctpParameters,
            // iceTransportPolicy: "all", 

          })
          this.logger(``, { functionName: ['createTransports'], logSeqNum: 2 }, false, [], LogLevelT.info)
          await this.addListenerToSendTransport()
          this.logger(``, { functionName: ['createTransports'], logSeqNum: 3 }, false, [], LogLevelT.info)
          //then we have to create a producer
          await this.createProducer()
          this.logger(``, { functionName: ['createTransports'], logSeqNum: 4 }, false, [], LogLevelT.info)
          // await this.createDataProducer()
          //then we have to create a reciver transport
          this.recieveTransport = this.device.createRecvTransport({
            dtlsParameters: createTransportsRes.data.transports[TRANSPORT_TYPE.recieve].dtlsParameters,
            iceCandidates: createTransportsRes.data.transports[TRANSPORT_TYPE.recieve].iceCandidates,
            iceParameters: createTransportsRes.data.transports[TRANSPORT_TYPE.recieve].iceParameters,
            sctpParameters: createTransportsRes.data.transports[TRANSPORT_TYPE.recieve].sctpParameters,
            id: createTransportsRes.data.transports[TRANSPORT_TYPE.recieve].id,
            // iceTransportPolicy: "all", 
          })
          this.logger(``, { functionName: ['createTransports'], logSeqNum: 5 }, false, [], LogLevelT.info)
          await this.addListenerToRecieveTransport()
          this.logger(``, { functionName: ['createTransports'], logSeqNum: "end" }, false, [], LogLevelT.info)
        }
      }
      catch (err) {
        this.logger(``, { functionName: ['createTransports'], logSeqNum: "catch" }, true, [], LogLevelT.error)
      }

    }
    catch (err) {
      this.logger(`err: ${ err }`, { functionName: ['createTransports'], logSeqNum: "catch" }, true, [], LogLevelT.error)
      throw err
    }
  }

  addListenerToSendTransport() {
    if (!this.sendTransport) {
      throw new Error('there is no send transport ')
    }
    this.sendTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
      console.log('###SEND_TRANSPORT### @connect')
      try {
        const res = await this.sendConnectTransportReq(TRANSPORT_TYPE.send, dtlsParameters)
        if (!res.data.success) {
          throw new Error('connect response was not successfull in send transport')
        }
        callback()
        console.log('sendTransport.connect#end::: ');
      } catch (error) {
        errback(error as Error)
        console.log('sendTransport.connect#catch::: err: ', error);
      }

    })
    this.sendTransport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
      console.log('###SEND_TRANSPORT### @produce')
      try {
        const res = await this.sendingProduceReq(kind, rtpParameters)
        callback({ id: res.data.producerId })
        console.log('sendTransport.produce#end::: ');
      } catch (error) {
        errback(error as Error)
        console.log('sendTransport.produce#catch::: err: ', error);
      }

    })
    this.sendTransport.on('producedata', async ({ sctpStreamParameters, label, protocol, appData }, callback, errback) => {
      console.log('###SEND_TRANSPORT### @produce data')
      try {
        const res = await this.sendProduceDataReq(sctpStreamParameters, label, protocol as "sctp" | "direct", appData)
        if (!res.data) {
          throw new Error('there is no server data')
        }
        callback({ id: res.data.dataProducerId })
        console.log('sendTransport.producedata#end:::');

      } catch (error) {
        errback(error)
        console.log('sendTransport.producedata#catch::: err: ', error);
      }
    })

    this.sendTransport.on('connectionstatechange', async (connectionState) => {
      switch (connectionState) {
        case 'connecting': {
          console.log('###SEND_TRANSPORT### @connectionstatechange: connecting')
          break
        }

        case 'connected': {
          console.log('SEND_TRANSPORT: @connectionstatechange: connected')
          await this.createDataProducer()
          break
        }

        case 'failed': {
          console.log('SEND_TRANSPORT: @connectionstatechange: failed')
          // this.setProcessStatus(Process_Status.EXITROOM)
          break
        }

        case 'disconnected': {
          console.log('SEND_TRANSPORT: @connectionstatechange: disconnected')
          break
        }

        case 'closed': {
          console.log('send transport: @connectionstatechange: closed')

          break
        }

        case 'new': {
          console.log('send transport: @connectionstatechange: new')
          break
        }
      }
    })

  }
  addListenerToRecieveTransport() {
    if (!this.recieveTransport) {
      throw new Error('there is no recieve transport')
    }
    this.recieveTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
      console.log('###RECIEVE_TRANSPORT### @connect')
      try {
        // const res = await this.sendConnectTransportReq(TRANSPORT_TYPE.recieve, dtlsParameters)
        const res = await sendConnectWebRtcTransportReq(
          this.token,
          {
            callId: this.callId,
            dtlsParameters,
            transportId: this.recieveTransport.id,
            name: this.name,
            mediaId: this.mediaId,
            type: TRANSPORT_TYPE.recieve,
            callCreatedAt: this.callCreatedAt,
            peerCreatedAt: this.peerCreatedAt
          }, this.mediaUrl

        )

        if (![200, 201].includes(res.status)) {
          return
        }
        callback()
        console.log('receiveTransport.connect#end::: ');
      } catch (error) {
        errback(error as Error)
        console.log('receiveTransport.connect#catch::: err: ', error);
      }
    })
    this.recieveTransport.on('connectionstatechange', async (connectionState) => {
      switch (connectionState) {
        case 'connecting': {
          console.log('###RECEIVE_TRANSPORT### @connectionstatechange: connecting')
          break
        }

        case 'connected': {
          //in the case of starting the consuming from the other peer's producer
          console.log('###RECEIVE_TRANSPORT### @connectionstatechange: connected')
          //then we have to allow the user to enter the call/room
          // this.setStatusUpdater('talking')
          this.setProcessStatus(Process_Status.CALLSTARTED)
          //then we have to start the talkTimer
          // this.startTheTimer()
          //where do we have to stop it
          //then we have try to loop over consumeDataParams and call     
          for (const otherPeerId of Object.keys(this.consumeDataParamQueue)) {
            this.consumeDataWithoutSendingReq(this.consumeDataParamQueue[otherPeerId])
          }
          break
        }


        case 'failed': {
          //in the case of stoping the server is emitted
          console.log('###RECEIVE_TRANSPORT### @connectionstatechange: failed')
          //then we have to end the call
          this.endCall({ sendReqToExitRoom: false, isForce: false, closeTheWs: true, calledFrom: "###RECEIVE_TRANSPORT### @connectionstatechange: failed", closeTheWSCore: true })
          this.setProcessStatus(Process_Status.EXITROOM)
          break
        }

        case "closed": {
          console.log('###RECEIVE_TRANSPORT### @connectionstatechange: closed')
          break
        }

        case "new": {
          console.log('###RECEIVE_TRANSPORT### @connectionstatechange: new')
          break
        }

      }
    })
    this.recieveTransport.on('produce', (arg_1, arg_2) => {
      console.log('###RECEIVE_TRANSPORT### @produce')
    })

    this.recieveTransport.on('producedata', (arg_1, arg_2, arg_3) => {
      console.log('###RECEIVE_TRANSPORT### @producedata')
    })
    // this.recieveTransport.on('')
  }



  async initialize(forReconnection: boolean) {
    this.logger(``, { functionName: ['initialize'], logSeqNum: "start" }, false, [], LogLevelT.info)

    try {
      if (this.hasInprocessCallInitialization) {
        return
      }
      this.setInprocessCallInitialization(true)
      //if http
      await this.httpCallInitialization(forReconnection)


      //if ws


      this.setInprocessCallInitialization(false)
      this.logger(`this.audioElements: ${ this.audioElements }`, { functionName: ['initialize'], logSeqNum: "end" }, false, [], LogLevelT.info);
    }
    catch (err) {
      this.setInprocessCallInitialization(false)
      this.logger(`err: ${ err }`, { functionName: ['initialize'], logSeqNum: "catch" }, true, [], LogLevelT.error);
    }
  }


  async httpCallInitialization(isReconnection: boolean) {
    console.log('initializeCall#start::: isReconnection: ', isReconnection);

    try {


      if (!isReconnection) {

        const createRoomResData = await this.sendCreateRoomReq()
        this.setMediaId(createRoomResData.data.mediaId)
        await this.loadDevice(createRoomResData.data.rtpCapabilities)
      }
      if (isReconnection) {
        // await this.sendJoinRoomReq()
      }
      await this.createTransports()

      this.consumeAllQueuedConsume()

      console.log('initializeCall#end:::');
    } catch (err) {
      console.log('initializeCall#catch::: err: ', err);
      throw err
    }
  }

  async loadDevice(routerRtpCapabilities: types.RtpCapabilities) {
    try {
      this.device = new Device()
      await this.device.load({ routerRtpCapabilities })
    }
    catch (err) {
      this.logger(`err: ${ err }`, { functionName: ['loadDevice'], logSeqNum: "catch" }, true, [], LogLevelT.error)
      throw new Error(`${ err.message }`)
    }
  }

  async createProducer() {
    console.log('createProducer#start::: ');
    if (!this.device) {
      throw new Error('there is no device')
    }
    if (!this.sendTransport) {
      throw new Error('there is no sendTransport')
    }
    try {
      let mediaConstraints: MediaStreamConstraints = {
        audio: true,
        video: false
      }

      if (this.producer) {
        throw new Error('we already created the producer')
      }
      if (!this.device.canProduce('audio')) {
        throw new Error(`device cant produce video and audio`)
      }
      //getting the stream from the browser
      let stream = await navigator.mediaDevices.getUserMedia(mediaConstraints)
      //extracting the track from stream
      let track = stream.getAudioTracks()[0]
      console.log('createProducer#1::: track: ', track);

      //then we have to create a producer
      this.producer = await this.sendTransport.produce({
        track
      })
      //then we have to add producer's listeners
      console.log('createProducer#end:::');

    }
    catch (err) {
      console.log('createProducer#catch::: err: ', err);

      throw err
    }
  }
  async consumeWithoutSendingReq(consumeParam: consumeResT) {
    this.logger(``, { functionName: ['consumeWithoutSendingReq'], logSeqNum: "start" }, false, [], LogLevelT.info)

    if (this.consumedPeers[consumeParam.otherPeerId]?.producerId === consumeParam.producerId) {
      this.logger(`this.consumedPeers[consumeParam.otherPeerId]?.producerId === consumeParam.producerId`, { functionName: ['consumeWithoutSendingReq'], logSeqNum: 1 }, false, [], LogLevelT.info)
      this.logger(`this.consumeParamQueue: ${ this.consumeParamQueue }`, { functionName: ['consumeWithoutSendingReq'], logSeqNum: 2 }, false, [], LogLevelT.info)
      this.logger(`this.consumers: ${ this.consumers }`, { functionName: ['consumeWithoutSendingReq'], logSeqNum: 3 }, false, [], LogLevelT.info)
      this.logger(`this.consumedPeers: ${ this.consumedPeers }`, { functionName: ['consumeWithoutSendingReq'], logSeqNum: 4 }, false, [], LogLevelT.info)
      return
    }
    if (!this.callId) {
      this.logger(`callId has not been set`, { functionName: ['consumeWithoutSendingReq'], logSeqNum: 5 }, true, [], LogLevelT.error)
      throw new Error('callId has not been set')
    }
    if (!this.token) {
      this.logger(`token has not been set`, { functionName: ['consumeWithoutSendingReq'], logSeqNum: 6 }, true, [], LogLevelT.error)
      throw new Error('token has not been set')
    }
    if (!this.recieveTransport) {
      //if we havent created recieveTransport yet in client, we have to queue that consumeParam, and we have to postpone the consume process after the creation of recievTransport
      this.logger(`no receiveTransport`, { functionName: ['consumeWithoutSendingReq'], logSeqNum: 7 }, true, [], LogLevelT.error)

      this.queueTheConsumeParam(consumeParam)
      return
    }
    try {

      const consumer = await this.recieveTransport.consume({
        id: consumeParam.id,
        producerId: consumeParam.producerId,
        kind: consumeParam.kind,
        rtpParameters: consumeParam.rtpParameters
      })
      //then we have to add consumer into consumers
      this.consumers[consumeParam.otherPeerId] = consumer
      this.consumedPeers[consumeParam.otherPeerId] = {
        peerId: consumeParam.otherPeerId,
        peerName: consumeParam.otherPeerName,
        producerId: consumeParam.producerId
      }

      this.logger(`this.consumers: ${ JSON.stringify(this.consumers) }`, { functionName: ['consumeWithoutSendingReq'], logSeqNum: 7 }, false, [], LogLevelT.info)

      // this.consumer = consumer
      //then we have to add listener to the consumer
      this.addListenerToConsumer(consumer, { callId: this.callId, token: this.token })
      //then we have to add the stream to the media
      const stream = new MediaStream();
      const consumerTrack = consumer.track
      stream.addTrack(consumerTrack)
      console.log('consumeWithoutSendingReq#7::: consumerTrack ', consumerTrack);
      consumerTrack.addEventListener("mute", (eve) => {
        this.logger(`eve: ${ JSON.stringify(eve) }`, { functionName: ['consumerTrack', '@mute'], logSeqNum: "start" }, false, [], LogLevelT.info)
      })
      consumerTrack.addEventListener("unmute", (eve) => {
        this.logger(`eve: ${ JSON.stringify(eve) }`, { functionName: ['consumerTrack', '@unmute'], logSeqNum: "start" }, false, [], LogLevelT.info)
      })
      consumerTrack.addEventListener("ended", (eve) => {
        this.logger(`eve: ${ JSON.stringify(eve) }`, { functionName: ['consumerTrack', '@ended'], logSeqNum: "start" }, false, [], LogLevelT.info)
      })



      // return {
      //     consumer,
      //     stream, 
      //     kind: consumeData.kind
      // }
      const elem = document.createElement('audio') as HTMLAudioElement
      elem.srcObject = stream
      elem.id = consumer.id
      elem.autoplay = true
      //then we have to append to the parent child
      this.logger(`this.parentHtmlRef: ${ this.parentHtmlRef }`, { functionName: ['consumeWithoutSendingReq'], logSeqNum: 8 }, false, [], LogLevelT.info)

      ////? added for a unknown bug
      this.parentHtmlRef.current?.append(elem)
      // this.startedConsumeStream++
      this.audioElements[consumer.id] = elem

    }

    catch (err) {
      this.logger(`err: ${ err }`, { functionName: ['consumeWithoutSendingReq'], logSeqNum: "catch" }, true, [], LogLevelT.error)
      throw err
    }
  }
  async consumeDataWithoutSendingReq(consumedDataParam: DoDataConsumePayloadT) {
    this.logger(``, { functionName: ['consumeDataWithoutSendingReq'], logSeqNum: "start" }, false, [], LogLevelT.info)

    if (this.consumedDataPeers[consumedDataParam.otherPeerId]?.dataProducerId === consumedDataParam.dataProducerId) {
      this.logger(`this.consumeDataParamQueue: ${ this.consumeDataParamQueue }`, { functionName: ['consumeDataWithoutSendingReq'], logSeqNum: 1 }, false, [], LogLevelT.info)
      this.logger(`this.dataConsumers: ${ this.dataConsumers }`, { functionName: ['consumeDataWithoutSendingReq'], logSeqNum: 2 }, false, [], LogLevelT.info)
      this.logger(`this.consumedDataPeers: ${ this.consumedDataPeers }`, { functionName: ['consumeDataWithoutSendingReq'], logSeqNum: 3 }, false, [], LogLevelT.info)
      return
    }
    this.logger(``, { functionName: ['consumeDataWithoutSendingReq'], logSeqNum: 4 }, false, [], LogLevelT.info)

    if (!this.callId) {
      this.logger(`callId has not been set`, { functionName: ['consumeDataWithoutSendingReq'], logSeqNum: 5 }, true, [], LogLevelT.error)
      throw new Error('callId has not been set')
    }

    this.logger(``, { functionName: ['consumeDataWithoutSendingReq'], logSeqNum: 6 }, false, [], LogLevelT.info)

    if (!this.token) {
      this.logger(`token has not been set`, { functionName: ['consumeDataWithoutSendingReq'], logSeqNum: 6 }, true, [], LogLevelT.error)
      throw new Error('token has not been set')
    }

    this.logger(``, { functionName: ['consumeDataWithoutSendingReq'], logSeqNum: 7 }, false, [], LogLevelT.info)

    if (!this.recieveTransport) {
      //if we havent created recieveTransport yet in client, we have to queue that consumeParam, and we have to postpone the consume process after the creation of recievTransport
      this.logger(`no receiveTransport yet`, { functionName: ['consumeDataWithoutSendingReq'], logSeqNum: 8 }, true, [], LogLevelT.error)
      this.queueTheConsumeDataParam(consumedDataParam)
      return
    }

    this.logger(`this.consumedDataParam: ${ consumedDataParam }`, { functionName: ['consumeDataWithoutSendingReq'], logSeqNum: 9 }, false, [], LogLevelT.info)

    try {
      // dataProducerId: '1ac75c18-969c-4522-b5c9-7151544b02f1',
      // id: 'e1c9fc15-fbd0-41db-ad1a-fe418f381fad',
      // type: 'sctp',
      // streamId: 0,
      // maxPacketLifeTime: undefined,
      // ordered: true,
      // maxRetransmits: undefined,
      // otherPeerId: 769313,
      // otherPeerName: 'Nokia'
      const dataConsumer = await this.recieveTransport.consumeData({
        sctpStreamParameters: {
          ordered: consumedDataParam.ordered,
          maxRetransmits: consumedDataParam.maxRetransmits,
          streamId: consumedDataParam.streamId || 0,
          protocol: consumedDataParam.protocol,
          label: consumedDataParam.label,

        },
        label: consumedDataParam.label,
        protocol: consumedDataParam.protocol,
        dataProducerId: consumedDataParam.dataProducerId,
        id: consumedDataParam.id,
        appData: consumedDataParam.appData,
      })

      //then we have to add consumer into consumers
      this.dataConsumers[consumedDataParam.otherPeerId] = dataConsumer
      this.consumedDataPeers[consumedDataParam.otherPeerId] = {
        peerId: consumedDataParam.otherPeerId,
        peerName: consumedDataParam.otherPeerName,
        dataProducerId: consumedDataParam.dataProducerId,
        streamId: consumedDataParam.streamId,

      }

      this.logger(`this.dataConsumers: ${ JSON.stringify(this.dataConsumers) }`, { functionName: ['consumeDataWithoutSendingReq'], logSeqNum: "end" }, false, [], LogLevelT.info)

      //then we have to add listener to the consumer
      this.addListenerToDataConsumer(dataConsumer, { callId: this.callId, token: this.token })

      // this.startedConsumeDataStream++
      delete this.consumeDataParamQueue[consumedDataParam.otherPeerId]
    }

    catch (err) {
      this.logger(`err: ${ err }`, { functionName: ['consumeDataWithoutSendingReq'], logSeqNum: "catch" }, true, [], LogLevelT.error)
      throw err
    }
  }

  queueTheConsumeParam(consumeParam: consumeResT) {
    console.log('queueTheConsumeParam#start::: consumeParam: ', consumeParam);
    this.consumeParamQueue[consumeParam.otherPeerId] = consumeParam
  }
  queueTheConsumeDataParam(consumeDataParam: DoDataConsumePayloadT) {
    console.log('queueTheConsumeDataParam#start::: consumeDataParam: ', consumeDataParam);
    this.consumeDataParamQueue[consumeDataParam.otherPeerId] = consumeDataParam
  }
  addListenerToConsumer(consumer: types.Consumer, { token, callId }: { token: string, callId: string }) {
    console.log('addListenerToConsumer#start::: ');
    consumer.on('trackended', () => {
      console.log(`###CONSUMER### @trackended`)
    })



    consumer.on('transportclose', () => {
      console.log(`###CONSUMER### @transportclose`)
      //then we have to end the call
      // if (!this.mediaHasBeenCrashed) {
      //   //we dont want to call endCall here when media gets crashed and mediasoup entities gets failed as a result of that
      //   this.endCall({ sendReqToExitRoom: false, isForce: false, closeTheWs: true, calledFrom: "###CONSUMER### @transportclose" })
      // }
    })

    consumer.on('@close', () => {
      console.log(`###CONSUMER### @close`)
    })

    //@ts-ignore
    consumer.on('close', () => {
      console.log(`###CONSUMER### @close`)
    })

    consumer.on('@getstats', async (arg_1: (stats: RTCStatsReport) => void, arg_2) => {
      console.log(`###CONSUMER### @getstats`)
      // console.log(`###CONSUMER### @getstats ---> ${await consumer.getStats()}`)

    })

    consumer.on('@pause', () => {
      console.log(`###CONSUMER### @pause`)
    })



    consumer.on('@resume', () => {
      console.log(`###CONSUMER### @resume`)
    })
  }
  addListenerToDataConsumer(dataConsumer: types.DataConsumer, { token, callId }: { token: string, callId: string }) {
    console.log('addListenerToDataConsumer#start::: ');
    dataConsumer.on('@close', () => {
      console.log(`###DATA_CONSUMER### @close`)
    })

    dataConsumer.on('transportclose', () => {
      console.log(`###DATA_CONSUMER### @transportclose`)
    })

    dataConsumer.on('error', (err) => {
      console.log(`###DATA_CONSUMER### @error: `, err)
    })

    dataConsumer.on('message', async (msg) => {
      console.log(`###DATA_CONSUMER### @message --->`, msg)
      if (msg === "PING") {
        if (this.dataProducer && !this.dataProducer.closed) {
          this.logger(`dataProducer sent a "PONG"`, { functionName: ['dataConsumer', '@message'], logSeqNum: 1 }, false, [LogScopeT.mediasoup, LogScopeT.listener], LogLevelT.info)

          this.dataProducer.send("PONG")
        }

      }
    })

    dataConsumer.on('open', () => {
      console.log(`###DATA_CONSUMER### @open`)
    })

    dataConsumer.on('close', () => {
      console.log(`###DATA_CONSUMER### Close`)
    })
  }
  async consumeAllQueuedConsume() {
    console.log('consumeAllQueuedConsume#start::: ');
    for (const consumeData of Object.values(this.consumeParamQueue)) {
      console.log('consumeAllQueuedConsume#1::: otherPeerId: ', consumeData.otherPeerId);
      this.consumeWithoutSendingReq(consumeData)
    }
  }

  async createDataProducer() {
    console.log('createDataProducer#start:::');

    try {
      this.dataProducer = await this.sendTransport.produceData({
        protocol: "sctp",
        maxRetransmits: 1,
        label: "SERVER_COMMUNICATION",
        ordered: true,
        // ordered: true,


      })

      this.addListenerToDataProducer(this.dataProducer)
      console.log('createDataProducer#end::: this.dataProducer: ', this.dataProducer);


      // new Promise((resolve, reject) => setTimeout(() => {
      //   this.dataProducer.send(JSON.stringify
      //     ("14141414141414144141414144141414"))
      // }, 2000))
    }
    catch (err) {
      console.log('createDataProducer#catch::: err: ', err);
      throw err
    }
  }
  addListenerToDataProducer(dataProducer: types.DataProducer) {
    console.log('addListenerToDataProducer#start::: ');

    dataProducer.on("@close", () => {
      console.log(`###DATA_PRODUCER### @close`)
      this.endCall({ sendReqToExitRoom: false, isForce: false, closeTheWs: true, calledFrom: "###DATA_PRODUCER### @close###", closeTheWSCore: true })
      this.setProcessStatus(Process_Status.EXITROOM)
    })
    dataProducer.on("bufferedamountlow", () => {
      console.log(`###DATA_PRODUCER### @bufferedamountlow`)
    })
    dataProducer.on("close", () => {
      console.log(`###DATA_PRODUCER### Close`)
    })
    dataProducer.on("error", (err) => {
      console.log(`###DATA_PRODUCER### @error:  `, err)
    })
    dataProducer.on("open", () => {
      console.log(`###DATA_PRODUCER### @open`)
    })
    dataProducer.on("transportclose", () => {
      console.log(`###DATA_PRODUCER### @transportclose`)
    })
  }
  closeTransport(type: 'SEND' | 'RECEVE') {
    switch (type) {
      case 'SEND': {
        if (this.sendTransport && !this.sendTransport.closed) {
          //if it has not been closed yet, we have to close it
          this.sendTransport.close()
        }
        this.sendTransport = undefined
        break
      }
      case 'RECEVE': {
        if (this.recieveTransport && !this.recieveTransport.closed) {
          //if it has not been closed yet, we have to close it
          this.recieveTransport.close()
        }
        this.recieveTransport = undefined
        break
      }
    }
  }
  closeProducer() {
    if (this.producer && !this.producer.closed) {
      this.producer.close()
    }
    this.producer = undefined
  }
  async endCall({ calledFrom, closeTheWs, isForce, sendReqToExitRoom, closeTheWSCore }: { sendReqToExitRoom: boolean, isForce: boolean, closeTheWs: boolean, calledFrom: string, closeTheWSCore: boolean }) {
    try {
      //if this method is called by the clicking on the exitRoom btn, we have to send a req to /exitRoom to remove the call inside the server
      //but if this method is called inside ###RECEIVE_TRANSPORT### @connectionstatechange: disconnected, 
      //it means call is ended by stoping the server or by exiting the other peer from the call
      if (sendReqToExitRoom) {
        //then we have to send a req to /exitRoom
        await this.sendExitRoomReq({ callId: this.callId, token: this.token, name: this.name, approvedMediaId: this.mediaId, isForce })
      }
      // //and we also have to stop the timer, in case of diconnection
      // this.stopTheTimer()
      //first we have to close transports and producers and consumers
      this.closeTransport('SEND')
      this.closeTransport('RECEVE')
      this.closeProducer()
      // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // stream.getTracks().forEach(track => track.stop());
      // for (const otherPeerId of Object.keys(this.consumers)) {
      //   this.logger(`before calling closeConsumer of otherPeerId: ${ otherPeerId } `, { functionName: ['endCall'], logSeqNum: 1 }, false, [], LogLevelT.info)
      //   this.closeConsumer(otherPeerId)
      // }
      // for (const otherPeerId of Object.keys(this.dataConsumers)) {
      //   this.logger(`before calling closeDataConsumer of otherPeerId: ${ otherPeerId } `, { functionName: ['endCall'], logSeqNum: 2 }, false, [], LogLevelT.info)
      //   this.dataConsumers[otherPeerId]?.close()
      //   delete this.dataConsumers[otherPeerId]
      //   delete this.consumeDataParamQueue[otherPeerId]
      //   delete this.consumedDataPeers[otherPeerId]
      // }
      if (closeTheWs) {
        //then we have to close the ws connection
        this.websocket_media?.close()
        this.websocket_media = undefined
      }
      if (closeTheWSCore) {
        //then we have to close the ws connection
        this.websocket_core?.close()
        this.websocket_core = undefined
      }
      //we dont want to remove the callId, talkHash, ws in case of reconnection
      // if (this.forgetTheCall) {
      //   //then we have clear all call specific data
      //   this.callId = undefined
      //   this.talkHash = undefined
      //   //we also have to reset the timer if the call is ended and not disconnected
      //   this.clearTheTime()
      // }

      this.logger(`this.parentHtmlRef: ${ this.parentHtmlRef } `, { functionName: ['endCall'], logSeqNum: 2 }, false, [], LogLevelT.info)
      this.logger(`this.audioElements: ${ this.audioElements } `, { functionName: ['endCall'], logSeqNum: 3 }, false, [], LogLevelT.info)

      // this.reqLevel = 'NO_REQ_YET'
      //we dont want to navigate to the CallEnded page in case of reconnection

      //then we have to update the process_status: CALL_ENDED
      // this.setProcessStatus(PROCESS_STATUS.callEnded)
      //to referesh some call related states
      this.mediaId = undefined
      this.callCreatedAt = undefined
      this.peerCreatedAt = undefined

    }
    catch (err) {
      this.logger(`err: ${ err } `, { functionName: ['endCall'], logSeqNum: "catch" }, true, [], LogLevelT.error)
      throw err
    }
  }
}

export default CallManager
export const callManager = new CallManager()

